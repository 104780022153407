import axios from "axios";
const state = {
    regimenes:[],
};
const getters = {};

const actions = {
    getRegimenes( {commit} ){
        axios
        .get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/tax_regime")
        .then(response => {
            commit('setRegimenes', response.data);
        });
    }
};

const mutations = {
    setRegimenes(state, data){
        state.regimenes = data;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}